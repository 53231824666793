import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Container from '../components/Container';
import Hero from '../components/Hero';
import ComponentWrapper from '../components/ComponentWrapper';
import Layout from '../components/Layout';
import Cta from '../components/Cta';
import Heading from '../components/typography/Heading';
import SEO from '../components/Seo';

const ContentWrapper = styled.article`
  max-width: 75rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 10rem auto 0 0;
  }
`;

const page = ({ data }) => {
  const { hero_title, hero_introductie, hero_img } = data.prismicPage.data;
  return (
    <Layout>
      <SEO title={hero_title.text} />
      <ComponentWrapper>
        <Hero img={hero_img.localFile.childImageSharp.fluid} />
      </ComponentWrapper>
      <Container>
        <ComponentWrapper>
          <ContentWrapper>
            <Heading>{hero_title.text}</Heading>
            <div dangerouslySetInnerHTML={{ __html: hero_introductie.html }} />
          </ContentWrapper>
        </ComponentWrapper>
      </Container>
      <Cta />
    </Layout>
  );
};

export default page;

export const pageQuery = graphql`
  query page($id: String!) {
    prismicPage(id: { eq: $id }) {
      data {
        hero_title {
          text
        }
        hero_introductie {
          html
        }
        hero_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80, maxHeight: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
